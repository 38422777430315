import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { pageDetailApiService } from '../../services/apiService';
import { FIXED_PAGES } from '../../services/constants';
function PrivacyPolicy(props) {

    const [pageData, setPageData] = useState([])
    const [blogsData, setBlogsData] = useState([])

    useEffect(() => {
        getAbousUsDetails()
        getBlogsData()
    }, [])

    const getAbousUsDetails = () => {
        let body = {
            pageTitle: 'ABOUT US'
        }
        pageDetailApiService.getPageDetails(body).then((response) => {
            if (response.data.status == true) {
                const responseData = response.data.data
                const responsePageData = responseData.pageData
                setPageData(responsePageData)
            }
        }).catch(err => {
            console.error(err)
        })
    }
    const getBlogsData = () => {
        const body = {
            pageTitle: FIXED_PAGES.HOME
        }
        pageDetailApiService.getPageDetails(body).then((response) => {
            if (response.data.status == true) {
                const responseData = response.data.data.pageData.testimonialsBlockData.testimonials
                setBlogsData(responseData)
            }
        })
    }


    return (
        <div className='about-container'>

            <div className='inner-hero'>
                <div className='inner-hero-image'>
                    <img src={pageData?.pilotBlockData?.pilotBlockImage} />
                </div>
                <div className='herotext wow fadeInUp'>
                    {/* <Container>
                        <h5>{pageData?.pilotBlockData?.pilotBlolckHeading}</h5>
                    </Container> */}
                </div>
            </div>

            <div class="section-padd">
                <div className='container'>
                    <h5>Privacy Policy</h5>
                    <p>Effective Date: This Privacy Policy was last updated on October 01, 2024.</p>
                    <p>Recovery Lab Wellness Center W.L.L. and its subsidiaries operating under the Recovery Lab Wellness Center brand (collectively, “Recovery Lab” , “we” , “us” , or “our”) respect your privacy and use your personal information in an effort to fulfill our commitment to offering and providing an unparalleled member experience.
                        This Privacy Policy (“Privacy Policy”) describes the privacy practices of RecoveryLab, including the types of personal information we obtain, how we use this personal information, with whom we share it and the choices available regarding our use of the information. This Privacy Policy also describes the measures we take to safeguard the personal information and how you can contact us about our privacy policies.</p>
                    <p>This Privacy Policy applies to the personal information we obtain when you interact with Recovery Lab, including but not limited to when they visit and use our Recovery Lab-branded locations (our “Clubs” or “Centers”), use our website <a href='https://www.recoverylabqatar.com' target='_blank'> https://www.recoverylabqatar.com</a>, and any other websites, mobile apps or online services or platforms of Recovery Lab on which this Privacy Policy is included or linked to (collectively, the “Sites”), apply for a job with us or otherwise connect with us.</p>
                    <p>This Privacy Policy does not apply to Recovery Lab products and services that have separate privacy policies, including without limitation the Privacy Policies of our affiliated companies or Furthermore, as further described herein.</p>
                    <p>Click on one of the links below to jump to the listed section:</p>
                    <p>• Information We Collect</p>
                    <p>• How We Use Your Information</p>
                    <p>• Third-Party Analytics Services</p>
                    <p>• Information Sharing</p>
                    <p>• Your Choices</p>
                    <p>• Text Messages</p>
                    <p>• Third-party Sites and Connected Accounts</p>
                    <p>• How We Protect Personal Information</p>
                    <p>• Retention of Personal Information</p>
                    <p>• Intended for Use by Adults</p>
                    <p>• Updates to Our Privacy Policy</p>
                    <p>• How To Contact Us</p>
                    <p>Information We Collect</p>
                    <p>The types of personal information we collect include:</p>
                    <p>• Contact information, such as name, email and postal address and phone number.</p>
                    <p>• Professional information, such as job title and employer details.</p>
                    <p>• Login credentials (e.g. username and password).</p>
                    <p>• Payment and financial information, such as your credit/debit card number, expiration date and security code, bank account number, and billing address.</p>
                    <p>• Demographic information you provide when you visit or become a member of a Recovery Lab Center, such as date of birth, age, and gender identity or expression.</p>
                    <p>• Information and data regarding your fitness, health, nutrition, activities, biological characteristics, and training goals, programs and progress.</p>
                    <p>• COVID-19 screening information, such as health declarations and vaccination status. </p>
                    <p>• Membership and usage information, such as membership ID, the Clubs you visit and the times you check in, workouts you log, classes you book, information provided when you purchase or schedule a sessions and treatments, and transaction history.</p>
                    <p>• Personal preferences such as favorite clubs, classes and instructors.</p>
                    <p>• Account information if you establish an online account with us, such as login credentials (e.g., username and password).</p>
                    <p>• Images and videos, including photographs and CCTV video footage.</p>
                    <p>• The geolocation of your device if you consent to the collection of this data.</p>
                    <p>• Biometric information such as facial geometry scans or other facial recognition information.</p>
                    <p>• Information in connection with applying for a job at Recovery Lab such as employer and employment history, education, job function, salary, professional certifications and other resume information.</p>
                    <p>• Social media handles, content and other information that you post to our social media pages or tag us on your social media pages, and information we obtain from third-party social media services (e.g., Facebook) if you choose to link to, or create or log in to your Recovery Lab online account through, these services.</p>
                    <p>• Information from third-party fitness devices, apps or services (e.g., Apple Health) or from our affiliates’ or third-party partners’ sites, apps, products, services or offerings (e.g., Recovery Lab) (the foregoing, collectively “Connected Accounts”), if you choose to connect any of the foregoing to your Recovery Lab account or our Sites, or if you chose to use your Recovery Lab account to log into or validate your account with any Connected Account.</p>
                    <p>• Other Information you provide to us, including through emails and other communications, surveys, information or content transmitted or uploaded through your Recovery Lab account (such as posts to message boards), and interactions with Recovery Lab and its personnel, or information when you enter any sweepstakes, contests or promotions we may offer.</p>
                    <p>In many cases, we will collect the personal information described above directly from you. In some cases, however, we will collect the information from other sources, such as:</p>
                    <p>• Affiliates, subsidiaries and parent companies: we may obtain your personal information from other Recovery Lab entities and affiliated companies.</p>
                    <p>• Business partners: we may obtain your information from other businesses with which we partner to provide you with products, services or offers we believe may be of interest to you, including our on-property partners such as cafes and through any Connected Accounts.</p>
                    <p>• Friends and family: we may obtain your information from your friends and family, such as when they refer to you our products, services or other offers.</p>
                    <p>• Other sources: we may obtain information from other sources such as publicly available databases, joint marketing partners, social media platforms, consumer data resellers, online advertising companies, and vendors who provide services on our behalf.</p>
                    <p>How We Use Your Information</p>
                    <p>We use the personal information we obtain about you to perform our contract with you or to take steps at your request prior to entering into a contract with you, including to:</p>
                    <p>• Provide our services, products and other offerings to you.</p>
                    <p>• Provide a seamless and integrated experience across Connected Accounts.</p>
                    <p>• Establish, manage and administer your membership and account with us, including verifying customer information and carrying out billing functions. We reserve the right to use any contact information held on file (including email, mailing address, and telephone or mobile phone) to contact to you when administering your membership.</p>
                    <p>• Provide a safe environment for our employees and members, including determining any potential COVID-19 exposure.</p>
                    <p>• Process and fulfil your purchases in connection with our membership opportunities, products and services (e.g., therapy sessions, retail purchases and spa treatments), and keeping you informed about the status of your membership and purchases.</p>
                    <p>• Provide customer support services.</p>
                    <p>• Manage career opportunities, including for recruitment purposes, candidate screening, interviewing and evaluation, and employee on boarding.</p>
                    <p>• Establish and manage our relationships with our vendors, providers, suppliers and consultants.</p>
                    <p>We also use the information we obtain based on our legitimate interests and business purposes, including to:</p>
                    <p>• Personalize and improve your experience on the Sites and at our Clubs.</p>
                    <p>• Provide you with a more personalized and relevant membership with Recovery Lab.</p>
                    <p>• Communicate with you about our products and services, and send you promotional and other marketing communications. See the “Your Choices” section below to learn how you can opt out of these communications.</p>
                    <p>• Communicate with you about, and administer your participation in, sweepstakes, surveys, events, programs, contests, promotions and other offers.</p>
                    <p>• Enable our affiliated companies and business partners (e.g., on-premises cafes and charitable organizations we partner with) to provide you with products and services, and send you information that you requested or that we think may interest you about their products or services.</p>
                    <p>• Respond to your requests and inquiries.</p>
                    <p>• Manage our business relationships.</p>
                    <p>• Operate, evaluate, develop, manage and improve our business, including by operating, administering, analysing and improving our Sites, products and services; conducting research and developing new products and services; managing and evaluating the effectiveness of our communications; performing accounting, auditing, billing, reconciliation and collection activities and other internal functions.</p>
                    <p>• Perform analytics (including market research, trend analysis, financial analysis, and anonymization of personal information);</p>
                    <p>• Protect against, identify and prevent safety and security issues, fraud and other criminal activity, claims and other liabilities; and</p>
                    <p>• Conduct investigations and comply with and enforce applicable legal requirements, relevant industry standards, and our policies and terms, and for the establishment, exercise or defence of legal claims, whether in court proceedings or in administrative or out-of-court procedures.</p>
                    <p>We use your personal information for these purposes because we have a legitimate business interest in providing services to our members, guests and other interested individuals that is not overridden by your interests, rights and freedoms to protect personal information about you.</p>
                    <p>Where we need to collect personal information by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (e.g., to process your payment). In this case, we may not be able to provide you with the product or service you have requested but we will endeavour to notify you if this is the case.</p>
                    <p>We also may use the information we collect about you in other ways for which we will provide specific notice at the time of collection and obtain your consent if required by applicable law.</p>
                    <p>Information Sharing</p>
                    <p>We share your personal information with our vendors and other advisors such as law firms and auditors, who perform services or functions on our behalf, such as vendors who assist Recovery Lab in maintaining the Sites, sending postal mail and email, managing and updating member lists and records, analysing data, and facilitating customer service. In accordance with applicable law, we have entered into appropriate written agreements with our vendors and we do not authorize our vendors to retain, use or disclose the information for any purpose other than the specific purpose of performing services on our behalf or complying with legal requirements. However, in certain instances vendors may use aggregated, de-identified information for their reasonable business purposes such as improving their products or services or for statistical reporting.</p>
                    <p>All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third party.</p>
                    <p>We may share your personal information with our affiliates, subsidiaries and parent companies. In addition, we may share your personal information with other businesses with which we partner to provide you with products, services or offers we believe may be of interest to you, including on-premises businesses and charitable organizations we partner with.</p>
                    <p>In accordance with applicable law, we may disclose information about you:</p>
                    <p>• If we are required to do so by law or legal process (such as a court order or subpoena).</p>
                    <p>• In response to requests by government agencies, such as law enforcement authorities.</p>
                    <p>• To establish or defend our legal rights or to enforce our membership agreement, terms and conditions and other contracts with you.</p>
                    <p>• When we believe disclosure is necessary or appropriate to prevent physical or other harm or financial loss.</p>
                    <p>• In connection with an investigation of suspected or actual illegal activity or violation of our membership rules and policies.</p>
                    <p>We may otherwise disclose information where we have obtained your consent to do so. We also reserve the right to assign or transfer any information we have about you in the event of any merger, acquisition, sale of assets, sale of our business, joint venture, reorganization, divestiture, dissolution, liquidation or other corporate transaction, involving us or any of our parent companies or affiliates.</p>
                    <p>Social Media and Third Party Apps: We may share information with social networks when you interact with the Sites (e.g., clicking a Facebook “like” button) or connect your social media accounts to a Site. If you elect to connect Third Party Apps to the Sites, your information may also be shared with these third parties. Please see below for more information on this, as we do not control these third parties or their privacy practices.</p>
                    <p>Your Choices</p>
                    <p>Where required by applicable law, we will obtain your consent to send you marketing communications. If you do not wish to receive any marketing emails, you can opt out of future mailings by clicking on the unsubscribe link located on the bottom of the relevant email. If you are a member and have an account on the Sites, you can unsubscribe at any time by logging in and adjusting your communications preferences. If you are a member, please note that even if you opt out of receiving promotional emails, you will continue to receive administrative and operational communications regarding your membership, such as notices regarding billing, session reminders or club closures.</p>
                    <p>Text Messages</p>
                    <p>In certain instances, the Sites may allow you to elect to communicate with Recovery Lab by using text message, including in connection with scheduling a visit to one of our Recovery Lab locations. By electing to receive text messages, you are expressly consenting to receiving text messages made using an automatic telephone dialing system from Recovery Lab or its service providers, and you also understand and agree that data rates and charges may apply and that you are solely responsible for all associated costs assessed by your telecommunication carrier or provider. Providing this consent is not a condition of becoming a member of Recovery Lab or receiving any goods or services from Recovery Lab.</p>
                    <p>Recovery Lab Wellness Center W.L.L., also known as Recovery Lab offers you the option to engage in SMS text conversations about your job application. By participating, you also understand that message frequency may vary depending on the status of your job application, and that message and data rates may apply. Please consult your carrier for further information on applicable rates and fees. Carriers are not liable for delayed or undelivered messages. By opting-in to receiving SMS text messages about your job application, you acknowledge and agree that your consent data, mobile number, and personal information will be collected and stored solely for the purpose of providing you with updates and information related to your job application. We are committed to protecting your privacy and shall not share or sell your consent data, mobile numbers, or personal information to third parties under any circumstances.</p>
                    <p>Third-party Sites and Connected Accounts</p>
                    <p>For your convenience, the Sites may contain links to other online services or otherwise enable you to connect with third party sites, features, plug-Ins, tools, widgets, social media platforms or apps such as Facebook, Instagram, Twitter, YouTube and Apple (collectively “Third-Party Sites”). Or they may enable you to connect or link to, or otherwise use your Recovery Lab account in connection with, Connected Accounts. Third-Party Sites operate independently from us, and the privacy practices of the relevant third parties, including details on the information they may collect about you, are subject to the privacy policies of those third-parties. You acknowledge and agree that your interactions with those Third-Party Sites and Connected Accounts, and your rights and obligations when accessing and using Third-Party Sites and Connected Accounts, are not governed by this Privacy Policy and will instead be governed by the terms and policies of those Third-Party Sites, and we encourage you to carefully read those terms and policies, as they may differ from ours.</p>
                    <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ARE NOT RESPONSIBLE FOR THE NATURE AND CONTENTS OF ANY FOR THE PRACTICES OF, OR THE COLLECTION, USE OR SHARING OF YOUR PERSONAL INFORMATION BY, ANY THIRD-PARTY SITES OR CONNECTED ACCOUNTS.</p>
                    <p>How We Protect Personal Information</p>
                    <p>Recovery Lab maintains administrative, technical and physical safeguards designed to protect personal information against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use.</p>
                    <p>Intended for Use by Adults</p>
                    <p>The Sites are intended for use by adults, and you may use the Sites only if you are at least eighteen (18) years of age or, consistent with our membership policies, have parental consent if you are sixteen (16) or seventeen (17) years old. The Sites are not intended for or directed to children under the age of thirteen (13), and we do not knowingly collect or store any personal information from children under the age of thirteen (13).</p>
                    <p>Updates to Our Privacy Policy</p>
                    <p>We may periodically update this Privacy Policy, in our discretion, to reflect change in our privacy practices, the Sites and applicable law. We will post a notice on the Sites to notify you of significant changes to our Privacy Policy and indicate at the top of this Privacy Policy when it was most recently updated. You should check this page from time to time to ensure you are happy with our current Privacy Policy.</p>
                    <p>Contact Recovery Lab </p>

                    <p>If you have questions regarding our Privacy Policy, please contact us at hello@recoverylabqatar.com. Please contact hello@recoverylabqatar.com for questions regarding your Recovery Lab membership. For general questions regarding Recovery Lab, our Centers or the Sites please visit
                         <a href='https://recoverylabqatar.com/contact' target='_blank'> https://recoverylabqatar.com/contactus</a>. Your feedback is always welcome and appreciated.</p>
                    <p></p>
                    <p></p>



                </div >
            </div>
        </div>
    )
}

export default PrivacyPolicy