import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GoArrowRight } from "react-icons/go";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { Link } from 'react-router-dom';
import { pageDetailApiService } from '../../services/apiService';
import { FIXED_PAGES } from '../../services/constants';
function TermsAndCondition(props) {

    const [pageData, setPageData] = useState([])
    const [blogsData, setBlogsData] = useState([])

    useEffect(() => {
        getAbousUsDetails()
        getBlogsData()
    }, [])

    const getAbousUsDetails = () => {
        let body = {
            pageTitle: 'ABOUT US'
        }
        pageDetailApiService.getPageDetails(body).then((response) => {
            if (response.data.status == true) {
                const responseData = response.data.data
                const responsePageData = responseData.pageData
                setPageData(responsePageData)
            }
        }).catch(err => {
            console.error(err)
        })
    }
    const getBlogsData = () => {
        const body = {
            pageTitle: FIXED_PAGES.HOME
        }
        pageDetailApiService.getPageDetails(body).then((response) => {
            if (response.data.status == true) {
                const responseData = response.data.data.pageData.testimonialsBlockData.testimonials
                setBlogsData(responseData)
            }
        })
    }


    return (
        <div className='about-container'>

            <div className='inner-hero'>
                <div className='inner-hero-image'>
                    <img src={pageData?.pilotBlockData?.pilotBlockImage} />
                </div>
                <div className='herotext wow fadeInUp'>
                    {/* <Container>
                        <h5>{pageData?.pilotBlockData?.pilotBlolckHeading}</h5>
                    </Container> */}
                </div>
            </div>

            <div class="section-padd">
                <div className='container'>

                    <p>Digital Terms Of Use</p>
                    <p>These Digital Terms of Use (together with the Additional Terms below, the “Terms”) set forth the terms and conditions applicable to and governing your access to and use of any and all website and apps, whether mobile, tablet, smart watch or otherwise, of Recovery Lab Wellness Center W.L.L and any of its affiliates or subsidiaries under the Recovery Lab brand (collectively, “Recovery Lab ”, “”we,” “us,” or “our”) on which these Terms are posted, including all features, functionalities, services and content) made available through such websites and apps (collectively, the “Digital Properties”) BY USING THE DIGITAL PROPERTIES, OR ANY OF THE FEATURES, FUNCTIONALITIES, SERVICES OR CONTENT MADE AVAILABLE THROUGH THE DIGITAL PROPERTIES, YOU ARE ACKNOWLEDGING THAT YOU HAVE READ, UNDERSTAND AND AGREED TO THESE TERMS AND EXPRESSLY AGREE THAT THEY FORM A BINDING CONTRACT BETWEEN YOU AND RECOVERY LAB.</p>
                    <p>CHANGES TO THE TERMS</p>
                    <p>These Terms may change as we continue to evolve our business, as well as the Digital Properties or any portion thereof. If we change these Terms, we will post the revised document here and such changes will be effective immediately upon that posting. Your continued use of the Digital Properties constitutes your acceptance of such changes and agreement to be bound by the modified Terms, and so we recommend that you review these Terms periodically when accessing or using the Digital Properties.</p>
                    <p>We recommend that you print or otherwise save a copy of these Terms, as updated from time to time, for your reference.</p>
                    <p>These terms were last updated: October 12, 2024</p>
                    <p>DISPUTE AGREEMENT AND CLASS ACTION WAIVER</p>
                    <p>Informal Dispute Distribution</p>
                    <p>Our goal is to do our best to ensure that every experience with Recovery Lab will exceed your expectations. If that doesn’t happen, we hope you will give us the opportunity to try to address any problem or concern. To do so, please contact us by visiting  <a href='https://recoverylabqatar.com/contact' target='_blank'> https://recoverylabqatar.com/contactus</a>. When contacting us, we ask that you include your name, address, phone number and email address, and a description of your problem or concern and any specific relief you seek.</p>
                    <p>Dispute Agreement</p>
                    <p>By using the Digital Properties, you and Recovery Lab agree to submit any and all Disputes (as defined below) to binding laws  pursuant to the Laws of the State of Qatar. (“Dispute Agreement”).</p>
                    <p>Class Action Waiver</p>
                    <p>By using the Digital Properties, you agree that any Dispute (as defined below) shall be conducted on an individual, not a class-wide basis, and that no such dispute proceedings may be consolidated with any other diapute or other legal proceedings involving Recovery Lab or any other person. You further agree that you, and anyone asserting a claim through you, will not be a class representative, class member, or otherwise participate in a class, representative, or consolidated proceeding against Recovery Lab. We and you agree that any Dispute between us may not consolidate more than one person’s claims, and may not otherwise preside over any form of a class or representative proceeding or claim (such as a class action, representative action, consolidated action or private attorney general action).</p>
                    <p>If the foregoing class action waiver (“Class Action Waiver”) or any portion thereof is found to be invalid, illegal, unenforceable, unconscionable, void or voidable, then the Dispute Agreement will be unenforceable, and the Dispute will be decided by a court governed by the laws of the State of Qatar. Any claim that all or part of the Class Action Waiver is invalid, illegal, unenforceable, unconscionable, void or voidable may be determined only by a court of competent jurisdiction governed by the laws of the State of Qatar.</p>
                    <p>Definition of Dispute</p>
                    <p>Except as described below, the term “Dispute” in this Dispute Agreement and Class Action Waiver means any dispute, claim, or controversy between you and Recovery Lab regarding any aspect of your relationship with Recovery Lab, whether based in contract, statute, regulation, ordinance, tort (including without limitation fraud, misrepresentation, fraudulent inducement, negligence, gross negligence or reckless behavior), or any other legal, statutory or equitable theory, whether related to the access to and use of the Digital Properties or otherwise, and includes the validity, enforceability or scope of these Terms, except for the scope, enforceability and interpretation of the Dispute Agreement and Class Action Waiver.</p>
                    <p>However, “Disputes” SHALL NOT include claims that all or part of the Class Action Waiver is invalid, unenforceable, unconscionable, void or voidable, or any claim for public injunctive relief, i.e., injunctive relief that has the primary purpose and effect of prohibiting alleged unlawful acts that threaten future injury to the general public. Such claims may be determined only by a court of competent jurisdiction governed by the Laws of the State of Qatar.</p>
                    <p>If you or Recovery Lab initiate a law case, you and we have the only choice of doing so in the State of Qatar.</p>
                    <p>Access to the digital properties</p>
                    <p>The Digital Properties are intended for use by adults who, by using the Digital Properties, consent to use of the Digital Properties in accordance with the State of Qatar laws. If you choose to access the Digital Properties from locations outside of the State of Qatar, you do so on your own initiative and at your own risk. By using or attempting to use the Digital Properties, you are representing to Recovery Lab that you meet the foregoing eligibility requirements and have the legal capacity to enter into and be bound by these Terms.</p>
                    <p>You are solely responsible for any charges incurred in obtaining access to the Digital Properties, including without limitation charges from your Internet service provider and/or wireless carrier. While you may be able to purchase certain goods or services through the Digital Properties, the Digital Properties themselves are currently provided for free. Recovery Lab reserves the right to change the nature of this relationship at any time, without prior notice or liability to you. In addition, access to certain Digital Properties or portions thereof may be limited to Recovery Lab members, as described in the “Your Account” section below.</p>
                    <p>Availability</p>
                    <p>Recovery Lab has no obligation to maintain the Digital Properties, in whole or in part, nor does it have any obligation to provide you with any related maintenance or support services. Recovery Lab reserves the right to render the Digital Properties, in whole or in part, temporarily or permanently unavailable or to otherwise terminate, suspend access to, replace, or modify the Digital Properties, in whole or in part, at any time and for any or no reason, without prior notice or liability to you.</p>
                    <p>YOUR ACCOUNT</p>
                    <p>Some portions of the Digital Properties may invite or require you to create an account and associated log-in credentials, and you may not be able to access certain Content or features, functionalities, or services of the Digital Properties, without creating and logging into such an account. In some instances, the right to create an account may be limited to current members of Recovery Lab in good standing. You agree to provide true, accurate and current information when creating an account, and you are responsible for updating your account information as needed to ensure it remains current. You are responsible for maintaining the confidentiality of your log-in credentials, and you hereby acknowledge and agree that you are fully responsible and liable for all usage and activities that occurs under your account, whether authorized by you or not. You agree to notify Recovery Lab immediately if you know of or suspect any unauthorized use of your account or password or any other breach of security related to your account. Notwithstanding anything to the contrary in these Terms, Recovery Lab reserves the right to deny the creation of, suspend access to or terminate any account(s), or to remove or modify Content, features, functionalities and/or services available to account holders, at any time in its sole discretion and without prior notice or liability to you.</p>
                    <p>LIMITED RIGHT TO USE THE DIGITAL PROPERTIES</p>
                    <p>Subject to your compliance with these Terms, Recovery Lab gives you the limited, non-exclusive, non-transferable, and revocable right to access and use the Digital Properties solely for your personal and non-commercial use. However, Recovery Lab does not give you any right to, and you hereby agree not to do any of the following:</p>
                    <p>• Use the Digital Properties or any portion thereof for any commercial purpose or for the benefit of any other person or entity, including without limitation by selling, renting, leasing, assigning, transferring, hosting, or otherwise commercially exploiting any Digital Property or any portion thereof. </p>
                    <p>• Use a false email address or other identifying information, impersonate any person or entity or otherwise misrepresent your relationship with RecoveryLab. </p>
                    <p>• Use the Digital Properties for any unlawful purposes. </p>
                    <p>• Copy, stream, reproduce, duplicate, archive, store (other than standard browser caching), download, publish, modify, make derivative works of, reverse engineer, translate, or distribute any Digital Property or portion thereof by any means, other than as expressly allowed by these Terms or as otherwise clearly contemplated by the features and functionalities of the Digital Properties – for example, by sharing Content through a social media “share” feature or downloading and using the Recovery Lab mobile app. You may also print or save to your computer, phone or personal device a reasonable number of pages from the Digital Properties, but solely for your own personal, internal and non-commercial purposes. </p>
                    <p>• Remove, alter, bypass, avoid or circumvent any copyright, trademark or other proprietary notice, digital rights management mechanisms or other content protection measures included in or associated with the Digital Properties or any Content. Similarly, you may not modify, remove, or otherwise interfere with any embedded player that may made available in connection with certain Content. </p>
                    <p>• Use any software robots, spiders, crawlers, or similar data mining, gathering or extraction tools or methods, whether automated, programmed or manual, including without limitation to access, acquire, copy, monitor or make submissions through any portion of the Digital Properties. </p>
                    <p>• Knowingly or intentionally take any other action that may impose an unreasonable burden or load on the Digital Properties or its servers and infrastructure.</p>
                    <p>Any unauthorized use by you, or otherwise under your account or on your computer or personal device, of the Digital Properties any portion thereof will immediately terminate the limited rights granted to you under these Terms, and such termination will be without prejudice to any other right or remedy Recovery Lab may have under applicable law or in equity.</p>
                    <p>ACCEPTABLE USE</p>
                    <p>Certain features or functionalities of the Digital Properties may permit you to upload, post or share your content, images, videos, pictures, statements and comments (collectively, “User Content”) and/or comment on or otherwise interact with other Digital Property users and their postings. You are solely responsible and liable for your User Content and for your use of the Digital Properties, including your interactions with other users and any disputes or disagreements that may arise in connection therewith. User Content may not reflect our views, and Recovery Lab does not endorse any User Content that you or others provide. You acknowledge and agree that you may find your interactions with other users, as well as their User Content, objectionable, offensive or otherwise inappropriate and you shall have no right against Recovery Lab based on User Content of others, to the maximum extent permitted by law.</p>
                    <p>As a condition of your right to access and use the Digital Properties, you hereby acknowledge and agree to comply with the following rules:</p>
                    <p>In the Public Eye: Your User Content and interactions with other users must be suitable for presentation in a public forum. User Content that you upload, post or share will not be treated as confidential and will be made available to, and may be read, collected, recorded, copied and used by, other users of the Digital Properties. Your User Content may not contain, any personally identifiable information, including contact information, for you or any person.</p>
                    <p>No Foul Play: You may not use the Digital Properties to bully, threaten, abuse, harass, degrade or mock any other user. Your User Content and interactions with other users may not contain, depict or promote any threats to any person, place, business or group or any damage to or theft of physical or personal property. You may not interfere with any other user from enjoying or using the Digital Properties.</p>
                    <p>Keep It Clean: Your User Content and interactions with other users may not contain, depict, or promote any libelous, defamatory, profane, obscene, pornographic, sexually explicit, indecent (including by virtue of nudity), lewd, or otherwise inappropriate content. Your User Content may not contain or depict, and you may not use, any symbols, words or slurs that are widely considered offensive to individuals of a certain race, gender, ethnicity, religion, sexual orientation or socioeconomic group.</p>
                    <p>Play It Safe: Your User Content and interactions with other users may not contain, depict or promote any unreasonably dangerous or reckless behavior or activity, including without limitation violence, abuse, cruelty to animals, use of illegal drugs, excessive or inappropriate use of alcohol or legal drugs or any conduct that constitutes a criminal offense or gives rise to civil liability.</p>
                    <p>Own It: You must own your User Content, and you may not upload, share, post, transmit, or otherwise distribute or facilitate the distribution of any content that is fraudulent or that infringes any patent, trademark, copyright, right of publicity, or other intellectual property, privacy or proprietary right of any third party.</p>
                    <p>Be Yourself: You may not impersonate any other individual or falsely state or misrepresent your professional or other affiliation Recovery Lab or with any other person or entity.</p>
                    <p>Not for Sale: Your User Content and interactions with other users may not contain, depict or promote, any advertising or commercial activity, including without limitation by offering products or services, conducting sweepstakes or contests, or otherwise sharing or transmitting unsolicited advertising, spam or junk or bulk messages. You may not use the Digital Properties to solicit or collect personally identifiable information from or about any other users.</p>
                    <p>Damage Control: You may not upload, share, post or otherwise distribute or facilitate the distribution of a software virus or any other computer code that is designed or intended to disrupt, damage, or limit the functioning of the Digital Properties, in whole or in part, or any systems or equipment of Recovery Lab or any other end user. You may not use any bots or automated software or device to upload, post or share User Content or to automatically post comments on other users’ content.</p>
                    <p>We may require you to provide proof or your compliance with these rules at any time. Recovery Lab may, but has no obligation to, monitor your use of the Digital Properties, including your User Content and interactions with other users, and reserves all rights to, but shall have no obligation to: (1) refuse to post, or to remove, modify or take other actions to regulate, any of your User Content, in whole or in part, that we determine in our sole discretion does not comply with these Terms or is otherwise objectionable; (2) become involved in any disputes or disagreements between you and other users and to take any other actions that we determine in our sole discretion are appropriate in connection therewith; and (3) disclose your User Content and/or any information provided to or through the Digital Properties or otherwise obtained during any such monitoring, as necessary to satisfy any law, regulation or governmental request.</p>
                    <p>If you violate any of these rules or otherwise misuse the Digital Properties, Recovery Lab reserves the right to, in its sole discretion, take, or refrain from taking, any and all steps available to RecoveryLab, including suspending or terminating your access to the Digital Properties, in whole or in part, reporting you to appropriate law enforcement or governmental officials or seeking other legal or equitable remedies.</p>
                    <p>INTELLECTUAL PROPERTY</p>
                    <p>As between you and Recovery Lab, Recovery Lab owns the Digital Properties and all content displayed or made available on or through, or otherwise included in, the Digital Properties, including without limitation all text, video clips, audio clips, graphics, trademarks, service marks, trade names, logos, icons, images, data, information, code and software, regardless of whether registered or unregistered, and any combinations and compilations thereof, but excluding any User Content (collectively, “Content”). The Digital Properties and Content, including the selection, coordination, arrangement and enhancement of Content and the design, layout and “look and feel” of each Digital Property, constitute valuable intellectual property of Recovery Lab, and are protected by applicable copyright, trademark and other applicable intellectual property rights and laws.</p>
                    <p>The Digital Properties and Content are licensed, not sold, to you, and your limited rights to access and use the Digital Properties and Content are conditioned upon your compliance with these Terms. No act of downloading or copying from, or otherwise using, the Digital Properties, even with Recovery Lab’s permission, will transfer any title, interest or right in or to any Digital Property or Content to you. Recovery Lab and its licensors hereby expressly reserves all rights not expressly granted in and to the Digital Properties and Content, and may revoke your license to use any part of the Digital Properties or Content at any time.</p>
                    <p>USER CONTENT + FEEDBACK</p>
                    <p>We welcome your feedback, comments, reviews, notes, messages, suggestions or other communications (collectively, “Feedback”) regarding the Digital Properties, as well as our club locations, services, products and other offerings. However, by posting or submitting Feedback on or through the Digital Properties, or otherwise sending Feedback to Recovery Lab, you are licensing such Feedback to Recovery Lab pursuant to the following paragraph. All Feedback will be treated as non-confidential, and it is the policy of Recovery Lab to not accept or consider any unsolicited ideas, confidential information, proprietary information or other Feedback that you do not wish to license to Recovery Lab, including without limitation ideas for new or improved products, services, apps, technologies, marketing, advertisements promotions or other services, products or offerings and any original creative materials such as stories, videos, computer code, images or artwork (all of the foregoing, collectively, “Unsolicited Ideas”), and therefore you agree not to post, submit or send to Recovery Lab, on an unsolicited basis. If, despite this policy, you nonetheless chose to post, submit or otherwise send to Recovery Lab any Unsolicited Idea, by doing so you acknowledging and agreeing that: (1) you are not posting, submitting or sending any confidential or proprietary information; (2) Recovery Lab has no obligation to review or use the Unsolicited Idea or to keep it confidential; and (3) Recovery Lab will consider the Unsolicited Idea as Feedback freely and irrevocably licensed to Recovery Lab, without restriction or liability and without notifying or compensating you, and you hereby agree to waive any and claims against Recovery Lab in connection with the foregoing.</p>
                    <p>You will continue to own your User Content and other Feedback (as defined below). However, by uploading, posting or sharing User Content and Feedback, you are granting, and hereby agree to grant, to Recovery Lab (including its licensees, affiliates, employees, contractors, agents, successors and assigns) a non-exclusive, perpetual, irrevocable, royalty-free, sublicenseable, transferable and worldwide license to use, reproduce, modify, disclose, publish, publicly display, publicly perform, distribute, modify, sublicense and create derivative works of your User Content and Feedback, and incorporate it into other works, in whole or in part and otherwise exploit your User Content and Feedback for any purpose whatsoever anywhere in the world, without restriction or liability and without notifying or compensating you in any way, and you hereby agree to waive any and claims against Recovery Lab in connection with the foregoing. Recovery Lab may, but is not obligated to, provide attribution of your User Content or Feedback, and you hereby waive any moral rights you may have in such User Content and Feedback. By uploading, posting or sharing any User Content in which you appear, you are also consenting to the recording, use and reuse by Recovery Lab (including its licensees, affiliates, employees, contractors, agents, successors and assigns) of your voice, actions, likeness, name, appearance, profile photograph, performance, biographical material, and any other identifying information in your User Content as used or modified by Recovery Lab in its exercise of the foregoing license.</p>
                    <p>If you upload, post or share any User Content or Feedback, you may not be able to remove it from the Digital Properties, and we make no guarantees that we will remove or return to you any User Content or Feedback, and are not liable for any loss of or damage to your User Content or Feedback.</p>
                    <p>PRIVACY</p>
                    <p>Our Privacy Policy available at  <a href='https://recoverylabqatar.com/privacy-policy' target='_blank'> https://recoverylabqatar.com/privacy-policy</a> describes the privacy practices of Recovery Lab. We encourage you to check this page from time to time to ensure you are happy with our current Privacy Policy.</p>
                    <p>THIRD PARTY SITES</p>
                    <p>For your convenience, the Digital Properties may contain links to websites, downloadable apps, or other digital properties, products or services of other persons or entities, including without limitation social media networks (collectively, “Third-Party Sites”). When you click on one of these links, you are leaving the Digital Properties and accessing a Third-Party Site over which Recovery Lab has no control. Including a link to a Third-Party Site is not and should not be viewed as an endorsement by, or affiliation with, Recovery Lab of that Third-Party Site, its operator or its contents, services and/or offerings. Your rights and obligations when accessing and using these Third-Party Sites are not governed by these Terms (or our Privacy Policy) and will instead be governed by the terms and policies of those Third-Party Sites, and we encourage you to carefully read those terms and policies of these Third-Party Sites, as their practices may differ from ours. TO THE MAXIMUM EXTENT PERMITTED BY LAW, RECOVERY LAB MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THIRD-PARTY SITES AND HAS NO RESPONSIBILITY FOR SUCH THIRD-PARTY SITES OR THE CONTENTS, FEATURES OR OPERATION OF SUCH THIRD-PARTY SITES. YOU ACKNOWLEDGE AND AGREE THAT YOUR DECISION TO ACCESS AND USE ANY THIRD-PARTY SITE IS AT YOUR SOLE RISK, AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RECOVERY LAB WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO, USE OF OR RELIANCE ON ANY THIRD-PARTY SITES.</p>
                    <p>HEALTH DISCLAIMERS</p>
                    <p>Recovery Lab is not a health care or medical provider.  The Digital Properties and the features, functionalities, services and Content made available therein, including without limitation any advice, information, treatments, therapies, exercises, regimens, nutritional plans, recipes or other materials (collectively, the “Fitness Features”), are provided for general informational purposes only, and do not constitute medical advice. The Fitness Features are not intended to be relied upon for determining the state of your health or in the diagnosis of any medical conditions and are not, and should not be used as, a substitute for a professional medical evaluation. We advise you to see your physician on a regular basis and to seek their advice prior to engaging in any of our services or if you have any questions or concerns regarding your health and fitness regimen or for the diagnosis of specific medical conditions.</p>
                    <p>Recovery Lab is not liable or responsible for any consequences of you having read, used or relied upon any Fitness Features. By using any Fitness Feature, you acknowledge and understand that it may involve or provide information regarding strength, flexibility, aerobic, cardio, meditation, regeneration or other exercises, all of which can be potentially hazardous activities. You should consult with your doctor prior to using any Fitness Feature. If you choose to use any Fitness Features, you should be in good health and have no disability, impairment, injury, disease or ailment preventing you from engaging in active or passive exercise or which would cause increased risk or injury or adverse health consequences as a result of using such Fitness Features, and you hereby assume all risks associated with using such Therapy Features. IF YOU EXPERIENCE ANY PAIN, DIFFICULTY, DIZZINESS, ILLNESS, OR DISCOMFORT, WHEN USING ANY FITNESS FEATURE, STOP AND CONSULT YOUR PHYSICIAN OR SEEK EMERGENCY MEDICAL ATTENTION IMMEDIATELY.</p>
                    <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, RECOVERY LAB MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THE FITNESS FEATURES AND IS NOT RESPONSIBLE FOR YOUR USE OF OR RELIANCE ON ANY SUCH FITNESS FEATURES. YOU ACKNOWLEDGE AND AGREE THAT YOUR DECISION TO RELY ON AND/OR USE ANY FITNESS FEATURE IS AT YOUR SOLE RISK, AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RECOVERY LAB WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, INCLUDING PERSONAL INJURY AND DEATH, ARISING OUT OF OR IN CONNECTION WITH YOUR RELIANCE ON OR USE OF ANY FITNESS FEATURE.</p>
                    <p>GENERAL DISCLAIMERS</p>
                    <p>While we endeavor to provide the best digital experience we can, including without limitation by offering accurate and current Content, we cannot and do not guarantee that the Digital Properties or any portion thereof will always be fully-functional, current or accurate. For up-to-date information on club hours, class schedule changes, contact information and any other information regarding any Recovery Lab club, its operations, programs, and/or offerings, please contact the relevant Recovery Lab club location or our corporate offices, as described in the “Contact Us” section below.</p>
                    <p>YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE DIGITAL PROPERTIES IS AT YOUR SOLE RISK, AND THAT THE DIGITAL PROPERTIES, AND ALL ASPECTS THEREOF, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. RECOVERY LAB MAKES NO REPRESENTATIONS OR WARRANTIES, AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, OF ANY KIND, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION: (1) WARRANTIES OF MERCHANTABILITY, TITLE, AVAILABILITY, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE; (2) WARRANTIES THAT THE DIGITAL PROPERTIES WILL MEET YOUR REQUIREMENTS, OR OPERATE WITH THE DEVICES, HARDWARE OR SOFTWARE YOU USE; OR (3) WARRANTIES THAT YOUR ACCESS TO AND USE OF THE DIGITAL PROPERTIES WILL BE AVAILABLE, UNINTERRUPTED, CURRENT, OR FREE FROM INACCURACIES, ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS OR CODE; OR THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED.</p>
                    <p>Recoverylabqatar.com will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of the State of Qatar.</p>
                    <p>Limitations of liability</p>
                    <p>YOU ALONE ARE RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER HARDWARE, SOFTWARE, SYSTEMS, AND NETWORKS, ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY INFORMATION FROM THE DIGITAL PROPERTIES, AND FOR ANY OTHER DAMAGE THAT MAY BE INCURRED.</p>
                    <p>YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE DIGITAL PROPERTIES IS TO STOP USING AND, WHERE APPLICABLE, UNINSTALL THE DIGITAL PROPERTIES.</p>
                    <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL RECOVERY LAB, ITS PARENTS, SUBSIDIARIES OR OTHER AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS, SERVICE PROVIDERS, AGENTS, SUCCESSORS OR ASSIGNS (COLLECTIVELY, “RECOVERY LAB GROUP”) BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY DIRECT, CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION FOR ANY DEATH, PERSONAL INJURY, LOSS OF USE, LOST DATA, LOST BUSINESS OR LOST PROFITS, ARISING OUT OF OR IN CONNECTION WITH THE DIGITAL PROPERTIES, OR THESE TERMS.</p>
                    <p>IN ADDITION, IF AND TO THE EXTENT RECOVERY LAB IS LIABLE FOR ANY DIRECT DAMAGES FOR ANY CLAIMS ARISING OUT OF OR IN CONNECTION WITH THE DIGITAL PROPERTIES, OR THESE TERMS, THEN, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF RECOVERY LAB FOR SUCH DIRECT DAMAGES WILL BE LIMITED TO THIRTY QATARI RIYALS (QAR 30.00), EVEN IF THIS REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>
                    <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. IF, PURSUANT TO APPLICABLE STATE OF QATAR LAW, THE LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES IS NOT PERMITTED, THE LIABILITY OF RECOVERY LAB SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>
                    <p>INDEMNIFICATION</p>
                    <p>You agree to defend, indemnify and hold the Recovery Lab harmless from and against any and all claims, liabilities, losses, damages, demands, complaints, actions, judgements, settlements, fines, penalties, costs and expenses (including without limitation reasonable attorneys’ fees), arising out of or in connection with: (1) your access to and/or use of the Digital Properties or any portion thereof; (2) your User Content, Feedback or Unsolicited Ideas, including without limitation any allegations of infringement; (3) your failure to comply with these Terms or with any applicable law, rule or regulation; (4) your infringement, misappropriation or violation of the Digital Properties or Content or of any third party’s intellectual property right; or (5) any activity occurring by or through your account, whether taken by you or another person accessing or using your account, with or without authorization.</p>
                    <p>Recovery Lab will use reasonable efforts to notify you of any claim, action or proceeding subject to the foregoing paragraph once it becomes aware of it, but any failure to provide you with notice shall not limit your indemnification obligations unless you can establish actual prejudice resulted from such failure. Recovery Lab reserves the right, at your expense, to assume the exclusive defense and control of any claim, action or proceeding for which you are required to provide indemnification, and you agree to cooperate with our defense of these claims. You may not settle any claims, actions or proceedings for which you are required to provide indemnification, without Recovery Lab’s prior written consent.</p>
                    <p>COPYRIGHT INFRINGEMENT POLICY</p>
                    <p>Recovery Lab respects the intellectual property rights of others and expects you to do the same. Recovery Lab reserves the right, but not the obligation, to terminate your license to use, or otherwise disable your account and access to the Digital Properties, in whole or in part, if we determines, in our sole and absolute discretion, that you are involved in infringing activity, including uploading, posting or sharing User Content that is infringing, regardless of whether you are a first-time or repeat infringer, and regardless of whether the material or activity is ultimately determined to be infringing. The foregoing processes does not limit our ability to pursue any other remedies we may have to address any suspected infringement or violation of these Terms.</p>
                    <p>If you believe that any User Content on the Digital Properties infringes your copyright, or the copyright of anyone on whose behalf you are authorized to act, you may submit a written complaint to our designated copyright agent at . Your complaint must include all of the following information:</p>
                    <p>• Identification of the copyrighted work(s) you claim is being infringed. </p>
                    <p>• Identification of the material that you claim is infringing and the URL or other sufficient information for Recovery Lab to locate that material within the Site. </p>
                    <p>• Your email address, telephone number and mailing address. </p>
                    <p>• A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law; and </p>
                    <p>• A statement that the information in your written complaint is accurate and that, under penalty of perjury, you are the copyright owner authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.  </p>
                    <p>• A physical or electronic signature of the copyright owner or the person authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.</p>
                    <p>TERMINATION</p>
                    <p>You may terminate these Terms at any time by ceasing to access and use the Digital Properties and, where applicable, uninstalling the Digital Properties. Please understand, however, if you subsequently decide to resume accessing and using any of the Digital Properties, you will again be bound by the then-current Terms. We may terminate these Terms, or otherwise terminate, suspend or restrict your access to and use of the Digital Properties, in whole or in part, at any time and without prior notice or liability to you, for any reason, including without limitation your failure to comply with any portion of these Terms.</p>
                    <p>However, even after these Terms have been terminated, the sections titled “Arbitration Agreement and Class Action Waiver,” “Intellectual Property,” “User Content + Feedback,” “Privacy,” “Health Disclaimers,” “General Disclaimers,” “Limitations of Liability,” “Indemnification,” “Governing Law,” “General,” “Gift Card Terms and Conditions,” “Promotional Gift Card Terms and Conditions,” “Personal Training Terms and Conditions” and any other provision which by its nature is intended to survive, will survive that termination.</p>
                    <p>Governing Law</p>
                    <p>Qatar is our country of domicile. These Terms, the Additional Terms below, and your access to and/or use of the Digital Properties, will be governed by and construed in accordance with the laws of the State of Qatar, without regard to its conflict of laws principles.</p>
                    <p>General</p>
                    <p>These Terms, together with our Privacy Policy, the Additional Terms below, and any other terms and conditions that may be made available with respect to certain Digital Properties or portions thereof, constitute the complete and exclusive agreement between you and Recovery Lab concerning your access to and use of the Digital Properties, and supersede and govern over all prior proposals, agreement or other communications. Nothing in these Terms shall be construed as creating any agency, partnership or other form of joint enterprise between you and Recovery Lab. Our failure to enforce any provision or exercise any right under these Terms will not constitute a waiver of such provision or right, nor will any waiver of any breach of these Terms act as a waiver of any other provision or a waiver of any future breaches. Any waiver of any provision of these Terms will be effective only if in writing and signed by an authorized representative of Recovery Lab. </p>
                    <p>Contact Us</p>
                    <p>Recovery Lab’s office is located at Zone 53, Street 725, Building 72, M Floor, Doha, State of Qatar.  If you have questions regarding these Terms, or our Digital Properties or our clubs, please contact us by visiting  <a href='https://recoverylabqatar.com/contact' target='_blank'> https://recoverylabqatar.com/contactus</a>. Your feedback is always welcome and appreciated.</p>
                    <p>ADDITIONAL TERMS FOR APPS</p>
                    <p>The following terms (the “Additional Terms for Apps”) apply to any applications designed for download and use on mobile, tablet, smart watch or other personal devices which Recovery Lab may make available as part of the Digital Properties (the “Apps”). You acknowledge and agree that the availability of each App is dependent upon the third-party app store from which you download the App (the “App Store”). You acknowledge that these Terms are between you and Recovery Lab, and not with the applicable App Store. Each App Store may have its own terms and conditions to which you must agree before downloading an App from it, and the limited rights Recovery Lab grants you to use the App are conditioned upon your compliance with any and all terms and conditions of such App Store.</p>
                    <p>In addition, the following terms and conditions apply to any of our Apps that you download from any Apple App Store (an “iOS App”):</p>
                    <p>1. Acknowledgement: You and Recovery Lab each acknowledge that the Terms, including these Additional Terms for Apps, are between you and Recovery Lab, and not with Apple Inc. (“Apple”), and that, subject to any limitations set forth in these Terms, Recovery Lab, not Apple, is solely responsible for the iOS App and its contents. To the extent the Terms or an Additional Terms set forth herein provide for usage rules applicable to an iOS App that are less restrictive than or otherwise conflict with the Apple App Store Terms of Service, the more restrictive or conflicting provision in such Apple App Store Terms of Service will govern and apply.</p>
                    <p>2. Scope of License: The license granted to you under the Terms is limited to a non-transferable license to use the iOS App on any iPhone, iPad, Apple Watch or other iOS product or device that you own or control, as permitted by the Usage Rules set forth in the App Store Terms of Service.</p>
                    <p>3. Maintenance and Support: To the extent any maintenance and support services are required under applicable law or expressly contemplated by these Terms, Recovery Lab, not Apple, is solely responsible for such services. You and Recovery Lab each acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the iOS App.</p>
                    <p>4. Warranty: Recovery Lab, not Apple, is solely responsible for any product warranties, whether express or implied by law, to the extent not effectively disclaimed or limited under the Terms. In the event of any failure of the iOS App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price (if any) for the iOS App to you. However, to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the iOS App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty, to the extent not effectively disclaimed or limited under the Terms, will be Recovery Lab’s sole responsibility.</p>
                    <p>5. Product Claims: You and Recovery Lab each acknowledge that Recovery Lab, not Apple, is responsible for addressing any claims you or a third party may have relating to the iOS App or your possession and/or use of that iOS App, including, but not limited to: (1) product liability claims; (2) any claim that the iOS App fails to conform to any applicable legal or regulatory requirement; and (3) claims arising under consumer protection or similar legislation. However, you acknowledge and agree that Recovery Lab’s responsibility for the foregoing claims is limited and disclaimed, to the maximum extent permitted by applicable law, as further described in these Terms.</p>
                    <p>6. Intellectual Property Rights: You and Recovery Lab each acknowledge that, in the event of any third party claim that the iOS App, or your possession and use of the iOS App, infringes that third party’s intellectual property rights, Recovery Lab, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim, subject to any disclaimers and limitations set forth in, and your indemnification obligations under, the Terms.</p>
                    <p>7. Third Party Beneficiary: You and Recovery Lab each acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of these Terms, and that, upon your acceptance of the Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary thereof.</p>
                    <p>Additional terms for Recovery Lab GIFT CARDs</p>
                    <p>The following terms and conditions (the “Additional Terms for Gift Cards”) apply to any Recovery Lab gift card originally purchased at any participating Recovery Lab location or through any Digital Property (each, an “Recovery Lab Gift Card”). By purchasing an Recovery Lab Gift Card, accepting and retaining an Recovery Lab Gift Card or using a Recovery Lab Gift Card, you agree to these Additional Terms for Gift Cards.  Recovery Lab reserves the right to change, amend or terminate the Additional Terms for Gift Cards and/or its Gift Card offerings, in its sole discretion, at any time with or without notice or liability to you.</p>
                    <p>USE OF THE RECOVERY LAB GIFT CARD</p>
                    <p>You may use the Recovery Lab Gift Card only to purchase eligible merchandise and pay for eligible services at any Recovery Lab Club.</p>
                    <p>REDEMPTIONS/BALANCES</p>
                    <p>Your use of the Recovery Lab Gift Card is limited to the current QAR value held on the card. You may obtain your balance by presenting your Recovery Lab Gift Card at any Recovery Lab Center. Recovery Lab Gift Cards may not be redeemed for cash, except to the extent required by applicable law.</p>
                    <p>RESTRICTIONS</p>
                    <p>You may not return, resell or use any Recovery Lab Gift Card for any unauthorized advertising, marketing, sweepstakes or other promotional purposes.</p>
                    <p>NO EXPIRATION DATE/SERVICE CHARGES</p>
                    <p>The Recovery Lab Gift Card has no expiration date, service charges or dormancy fees.</p>
                    <p>PROMOTIONAL CARDS</p>
                    <p>These Additional Terms for Gift Cards do not apply to gift cards or other stored value cards that are given away for free or distributed as a reward, incentive, or as part of a marketing or promotional program (“Promotional Cards”). Promotional Cards may have different terms and expiration dates, to the extent permitted by applicable law. See below for terms and conditions applicable to Promotional Cards.</p>
                    <p>LOST OR STOLEN CARDS</p>
                    <p>Protect your Recovery Lab Gift Card as if it were cash and safeguard the Recovery Lab Gift Card from unauthorized use. Except where required by applicable law, neither Issuer nor Recovery Lab is responsible for and will not replace Recovery Lab Gift Cards if lost, stolen, damaged or destroyed.</p>
                    <p>MISUSE OF THE CARD</p>
                    <p>Issuer and Recovery Lab each reserve the right, in their sole discretion, to suspend or terminate use of any Recovery Lab Gift Card if Issuer or Recovery Lab suspects any fraud or misuse of an Recovery Lab Gift Card. Issuer or Recovery Lab may cancel the Recovery Lab Gift Card at any time, without notice or liability to you.</p>
                    <p>PROPERTY RIGHTS</p>
                    <p>The consideration paid for the Recovery Lab Gift Card, including any unredeemed balances, is the property of the Issuer.</p>
                    <p>NO WARRANTIES</p>
                    <p>TO MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RECOVERY LAB AND ISSUER MAKE NO REPRESENTATIONS OR WARRANTIES AND HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE RECOVERY LAB GIFT CARDS, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NEITHER ISSUER NOR RECOVERY LAB ASSUME ANY RESPONSIBILITY FOR ANY LOSS OF ANY KIND ARISING OUT OF YOUR USE OF ANY RECOVERY LAB GIFT CARD. IN THE EVENT AN RECOVERY LAB GIFT CARD IS NOT FUNCTIONAL, YOUR SOLE REMEDY AND ISSUER’S AND RECOVERY LAB’S SOLE LIABILITY SHALL BE THE REPLACEMENT OF THE RECOVERY LAB GIFT CARD AT ITS THEN-CURRENT VALUE.</p>
                    <p>GOVERNING LAW</p>
                    <p>Notwithstanding the “Governing Law” section of the Terms, the laws of the State of Qatar govern the Recovery Lab Gift Cards and these Additional Terms for Gift Cards, without regard to choice of law principles.</p>
                    <p>SEVERABILITY</p>
                    <p>If any one or more of the provisions of these Additional Terms for Gift Cards shall be for any reason whatsoever held invalid by a court of competent jurisdiction, then such provisions shall be deemed severable from the remaining provisions and shall in no way affect the validity or enforceability of the other provisions of these Additional Terms for Gift Cards.</p>
                    <p>ENTIRE AGREEMENT</p>
                    <p>Except as specifically stated otherwise, these Additional Terms for Gift Cards, together with the Terms, set forth the entire understanding relating to the subject matter hereof, and supersede all prior understandings, written or oral.</p>
                    <p>CONTACT</p>
                    <p>For any questions or concerns regarding the Recovery Lab Gift Card program, please contact us as described in the “Contact Us” section above.</p>
                    <p>Additional terms for PROMOTIONAL CARDs</p>
                    <p>The following terms and conditions (the “Additional Terms for Promotional Cards”) apply to Recovery Lab gift cards or other stored value cards that are given away for free or distributed as a reward, incentive, or as part of a marketing or promotional program (“Promotional Cards”). By using, accepting and/or retaining a Promotional Card, you agree to these Additional Terms for Promotional Cards. Recovery Lab reserves the right to change, amend or terminate the Additional Terms for Promotional Cards and/or its Promotional Card offerings, in its sole discretion, at any time without notice or liability to you.</p>
                    <p>USE OF THE PROMOTIONAL CARD</p>
                    <p>You may use the Promotional Card only to purchase eligible merchandise and pay for eligible services at any Recovery Lab Center. You may not use the Promotional Card to pay for membership dues.</p>
                    <p>REDEMPTIONS/BALANCES</p>
                    <p>Your use of the Promotional Card is limited to the current dollar value held on the card. You may obtain your balance by presenting your Promotional Card at any Recovery Lab Center. Promotional Cards may not be redeemed for cash.</p>
                    <p>RESTRICTIONS</p>
                    <p>You may not return, resell or use any Promotional Card for any unauthorized advertising, marketing, sweepstakes or other promotional purposes.</p>
                    <p>EXPIRATION DATE</p>
                    <p>All Promotional Card will expire 90 days after the issuance date, unless a different expiration date is expressly set forth on the Promotional Card itself.</p>
                    <p>LOST OR STOLEN CARDS</p>
                    <p>Protect your Promotional Card as if it were cash and safeguard the Promotional Card from unauthorized use. Except where required by applicable law, neither Issuer nor Recovery Lab is responsible for and will not replace Promotional Cards if lost, stolen, damaged or destroyed.</p>
                    <p>MISUSE OF THE CARD</p>
                    <p>Issuer and Recovery Lab each reserve the right, in their sole discretion, to suspend or terminate use of any Promotional Card if Issuer or Recovery Lab suspects any fraud or misuse of a Promotional Card. Issuer or Recovery Lab may cancel the Promotional Card at any time, without notice or liability to you.</p>
                    <p>NO WARRANTIES</p>
                    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RECOVERY LAB AND ISSUER MAKE NO REPRESENTATIONS OR WARRANTIES AND HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE PROMOTIONAL CARDS, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NEITHER ISSUER NOR RECOVERY LAB ASSUMES ANY RESPONSIBILITY FOR ANY LOSS OF ANY KIND ARISING OUT OF YOUR USE OF ANY PROMOTIONAL CARD. IN THE EVENT A PROMOTIONAL CARD IS NOT FUNCTIONAL, YOUR SOLE REMEDY AND ISSUER’S AND RECOVERY LAB’S SOLE LIABILITY SHALL BE THE REPLACEMENT OF THE PROMOTIONAL CARD AT ITS THEN-CURRENT VALUE.</p>
                    <p>GOVERNING LAW </p>
                    <p>Notwithstanding the “Governing Law” section of the Terms, the laws of the State of Qatar govern the Promotional Card and these Additional Terms for Promotional Cards, without regard to choice of law principles.</p>
                    <p>SEVERABILITY</p>
                    <p>If any one or more of the provisions of these Additional Terms for Promotional Cards shall be for any reason whatsoever held invalid by a court of competent jurisdiction, then such provisions shall be deemed severable from the remaining provisions and shall in no way affect the validity or enforceability of the other provisions of these Additional Terms for Promotional Cards.</p>
                    <p>ENTIRE AGREEMENT</p>
                    <p>Except as specifically stated otherwise, these Additional Terms for Promotional Cards, Terms, set forth the entire understanding relating to the subject matter hereof, and supersede all prior understandings, written or oral.</p>
                    <p>CONTACT</p>
                    <p>For any questions or concerns regarding any Promotional Card, please contact us as described in the “Contact Us” section above.</p>
                    <p>Additional terms for PERSONAL TRAINING</p>
                    <p>The following terms and conditions (the “Additional Terms for Services”) apply to all services originally purchased at any participating Recovery Lab location or through any Digital Property (each, a “Session”). By using, accepting and/or retaining a Session, you agree to these Additional Terms for Services. Recovery Lab reserves the right to change, amend or terminate the Additional Terms for Services and/or its Services offerings, in its sole discretion, at any time with or without notice or liability to you.</p>
                    <p>24 HOUR CANCELLATION POLICY</p>
                    <p>A Recovery Lab member that has made an appointment for a Session may cancel or change the time of the Session provided that a minimum of 24-hours’ prior notice is given. You can make these changes via our website or mobile app in your account to the Digital Properties, or by contacting the Recovery Lab Center directly. Members will be charged for the full cost of the session on any late cancellations or no-show appointments.</p>
                    <p>Refunds if eligible, will be done only through the original mode of payment. </p>
                    <p>PAYMENTS</p>
                    <p>We accept payments online using Visa and MasterCard credit/debit cards in QAR. Payment for Sessions is required to be made by the member on or before the date of the Session. If a member has no Session inventory, charges for late cancellations or no show appointments for Sessions will be charged to the member’s credit card on file with Recovery Lab under the membership agreement. In addition, charges for Sessions which are used by a member but not otherwise paid for will be charged to the member’s credit card on the day the Session is used.</p>
                    <p>DISCOUNTS</p>
                    <p>Recovery Lab may offer discounts on Sessions to members who purchase a package of Sessions. These discounts only apply to the number of Sessions purchased as a group. Any further or additional Sessions will be charged at the regular rate then in effect for individual Sessions.</p>
                    <p>REDEMPTION</p>
                    <p>You may obtain information on your balance of Sessions in the Appointments tab in your account to the Digital Properties or at any Recovery Lab Center. Sessions may not be redeemed for cash.</p>
                    <p>EXPIRATION POLICY</p>
                    <p>All sales are final. Session expire on the date of expiry mentioned at the date of purchase.</p>
                    <p></p>
                    <p></p>



                </div>

            </div>
        </div >
    )
}

export default TermsAndCondition